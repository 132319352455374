import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

import logoWhite from "../images/logo.png";
import img404 from "../images/404.png";

import { Helmet } from "react-helmet"

import "./../components/ErrorPage.css";


function NotFoundPage() {
    const [languageGreek, setLanguageGreek] = useState(false)

    useEffect(() => {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang.toLowerCase().includes("el")) {
            setLanguageGreek(true)
        } else {
            setLanguageGreek(false)
        }
    }, [])
    return (
        <div>
            <Helmet>
                <title>404 | Not Found</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div style={{ borderBottom: "1px solid #e8e8e8" }}>
                <Link to={"/"} className="py-6 block">
                    <img className="w-56 md:w-56 m-auto" src={logoWhite} alt="white-logo" />
                </Link>
            </div>
            <div className="error-page-container">
                <div className="flex flex-wrap justify-center">
                    <h1>404</h1>
                    { !languageGreek
                        ?
                        <React.Fragment>
                            <h1 className="py-8 text-xl w-full text-center" style={{ color: "#77838F" }}>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</h1>
                            <Link to={"/en"} className="main-button-blue mt-4 md:mt-0" style={{width: "auto", height: "auto"}} >
                                Return to Homepage
                            </Link>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h1 className="py-8 text-xl w-full text-center" style={{ color: "#77838F" }}>ΩΧΧΧ! Η ΣΕΛΙΔΑ ΠΟΥ ΠΡΟΣΠΑΘΕIΤE ΝΑ ΔΕΙΤΕ ΔΕΝ ΜΠΟΡΕΙ ΝΑ ΒΡΕΘΕΙ.</h1>
                            <Link to={"/"} className="main-button-blue mt-4 md:mt-0" style={{width: "auto", height: "auto"}} >
                                Επιστροφή στην Αρχική
                            </Link>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}


export default NotFoundPage
